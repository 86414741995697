import React, { useEffect, useState } from "react";
import { Col, Row, Container, Card, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AdminOverview from "./overview.component";
import Users from "./users/list/users.page";
import Games from "./games/games.page";

function AdminPage() {
  const { t } = useTranslation();

  return (
    <div className="admin-nav">
      <Tabs>
        <Tab eventKey="overview" title="Overview">
          <AdminOverview />
        </Tab>
        <Tab eventKey="games" title="Games">
          <Games />
        </Tab>
        <Tab eventKey="users" title="Users">
          <Users />
        </Tab>
      </Tabs>
    </div>
  );
}

export default AdminPage;
