import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import CreateUserModal from "./create.modal";
import { fetchUsers } from "../../../../repositories/user.api";
import ConfirmModal from "./confirm.modal";
import PaginationContainer from "../../../../components/pagination.component";
import moment from "moment";

function Users() {
  const { t } = useTranslation();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [allRowCount, setAllRowCount] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(25);
  const [showCreateUserModal, setShowCreateUserModal] = useState({
    availability: false,
  });
  const [showConfirmModal, setShowConfirmModal] = useState({
    availability: false,
  });

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async (page = 1, query = "") => {
    let skip = (page - 1) * 25;

    let { list, count } = await fetchUsers(query, skip, limit);
    setList(list);
    setPage(page);
    setSkip(skip);
    setAllRowCount(count);
  };

  const handleSearchQuery = async (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length > 2) {
      await fetchList(1, e.target.value);
    } else {
      await fetchList(1, "");
    }
  };

  return (
    <>
      <div className="mb-4 feature-hero">
        <Container>
          <Row>
            <Col className="section-hero">
              <h3>{t("users")}</h3>
            </Col>

            <Col className="section-hero">
              <Button
                onClick={() => setShowCreateUserModal({ availability: true })}
              >
                {t("create")}
              </Button>
            </Col>

            <Col sm={12}>
              <Form.Group className="mt-5">
                <Form.Control
                  type="text"
                  maxLength={1000}
                  className="block-text-input"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchQuery}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </div>
      <Container style={{ paddingTop: 40 }}>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>{t("id")}</th>
                  <th>{t("name")}</th>
                  <th>{t("email")}</th>
                  <th>{t("balance")}</th>
                  <th>{t("is_admin")}</th>
                  <th>{t("status")}</th>
                  <th>{t("last_login")}</th>
                  <th>{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {list.map((user) => (
                  <tr key={`user-${user._id}`}>
                    <td>{user._id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.balance} {t("base_currency")}
                    </td>
                    <td>
                      {user.is_admin ? (
                        <span className="tag">{t("true")}</span>
                      ) : (
                        <span className="tag">{t("false")}</span>
                      )}
                    </td>
                    <td>
                      {user.status ? (
                        <span className="tag">{t("true")}</span>
                      ) : (
                        <span className="tag">{t("false")}</span>
                      )}
                    </td>
                    <td>
                      {moment((user.timing || {}).last_login).format(
                        "YYYY/MM/DD HH:mm",
                      )}
                    </td>
                    <td>
                      <Button
                        variant="dark"
                        className="btn-circle"
                        as={Link}
                        to={`/console/user/${user._id}`}
                      >
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                      <Button
                        variant="dark"
                        className="btn-circle"
                        onClick={() =>
                          setShowConfirmModal({
                            availability: true,
                            userId: user._id,
                          })
                        }
                      >
                        <i className="fa-solid fa-trash"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        {allRowCount > 0 && (
          <PaginationContainer
            className="mb-5"
            currentPage={page}
            totalCount={allRowCount}
            limit={25}
            action={fetchList}
          />
        )}
      </Container>

      <CreateUserModal
        showCreateUserModal={showCreateUserModal}
        setShowCreateUserModal={() => setShowCreateUserModal}
        forceUpdate={fetchList}
      />
      <ConfirmModal
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        forceUpdate={fetchList}
      />
    </>
  );
}

export default Users;
