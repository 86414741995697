import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import { fetchGames } from "../../../repositories/game.api";
import PaginationContainer from "../../../components/pagination.component";
import Table from "react-bootstrap/Table";
import moment from "moment";

function AdminGames() {
  const { t } = useTranslation();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [allRowCount, setAllRowCount] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(25);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async (page = 1, query = "") => {
    let skip = (page - 1) * 25;

    let { list, count } = await fetchGames(query, skip, limit);
    setList(list);
    setPage(page);
    setSkip(skip);
    setAllRowCount(count);
  };

  const handleSearchQuery = async (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length > 3) {
      await fetchList(e.target.value, 0, 50);
    }
  };

  return (
    <>
      <div className="mb-4 feature-hero">
        <Container>
          <Row>
            <Col className="section-hero">
              <h3>{t("games")}</h3>
            </Col>
            <Col className="section-hero"></Col>

            <Col sm={12}>
              <Form.Group className="mt-5">
                <Form.Control
                  type="text"
                  className="block-text-input"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchQuery}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </div>
      <Container style={{ paddingTop: 40 }}>
        <Table responsive>
          <thead>
            <tr>
              <th>{t("name")}</th>
              <th>{t("status")}</th>
              <th>{t("created_at")}</th>
              <th>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {list.map((game) => (
              <tr key={`game=${game._id}`}>
                <td>{game.name}</td>
                <td>
                  <span className="tag">{game.status}</span>
                </td>
                <td>{moment(game.created_at).format("YYYY/MM/DD HH:mm")}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>

        {allRowCount > 0 && (
          <PaginationContainer
            className="mb-5"
            currentPage={page}
            totalCount={allRowCount}
            limit={25}
            action={fetchList}
          />
        )}
      </Container>
    </>
  );
}

export default AdminGames;
