import React, { Suspense, lazy } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "react-error-boundary";

import "./utils/i18n.js";
import store, { persistor } from "./reducers/index";
import TopLoading from "./components/loading.component";
import ErrorPage from "./error.page";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/app.css";
import NewView from "./pages/game/tables/new/new_view";
import ForgetConfim from "./pages/auth/forget/forget-confirm.page";
import PrivacyPolicy from "./pages/landing/privecy.page";
import AdminPage from "./pages/admin/index.page";

const Console = lazy(() => import("./pages/console.layout"));
const GameSummery = lazy(() => import("./pages/game/landing/index.page"));
const Pricing = lazy(() => import("./pages/landing/pricing/pricing.page"));
const Forget = lazy(() => import("./pages/auth/forget/forget.page"));
const Contact = lazy(() => import("./pages/landing/contact/index.page.js"));
const Feature = lazy(() => import("./pages/landing/features/feature.page.js"));

const Landing = lazy(() => import("./pages/landing/home/index.page"));
const Login = lazy(() => import("./pages/auth/login/login.page"));
const Register = lazy(() => import("./pages/auth/register/register.page"));

const Game = lazy(() => import("./pages/game/game.layout"));
const Achievements = lazy(() =>
  import("./pages/game/services/achievement/list/index.page")
);
const Achievement = lazy(() =>
  import("./pages/game/services/achievement/single/index.page")
);

const Panel = lazy(() => import("./pages/game/panel/index.page"));

const JourneyList = lazy(() =>
  import("./pages/game/services/logic/list/index.page")
);

const Journey = lazy(() =>
  import("./pages/game/services/logic/single/index.page")
);

const NewAchievement = lazy(() =>
  import("./pages/game/services/achievement/add/index.page")
);
const Leaderboards = lazy(() =>
  import("./pages/game/services/leaderboard/list/index.page")
);
const Leaderboard = lazy(() =>
  import("./pages/game/services/leaderboard/single")
);
const NewLeaderboard = lazy(() =>
  import("./pages/game/services/leaderboard/add/index.page")
);

const Computing = lazy(() => import("./pages/game/computing"));
const Tables = lazy(() => import("./pages/game/tables/list/list"));
const Chat = lazy(() => import("./pages/game/services/chats"));
const Storage = lazy(() => import("./pages/game/storage/index.page"));
const User = lazy(() => import("./pages/game/services/user/single"));
const UserAccount = lazy(() => import("./pages/admin/users/single/user.page"));
// const Party = lazy(() => import("./pages/game/parties/details"));
const UsersList = lazy(() => import("./pages/game/services/user/list"));
const EconomyList = lazy(() =>
  import("./pages/game/services/economy/list/index.page")
);
const TableView = lazy(() => import("./pages/game/tables/browser/index.page"));
const NewTable = lazy(() => import("./pages/game/tables/new"));

const Settings = lazy(() => import("./pages/home/settings/index.page"));
const Wallet = lazy(() => import("./pages/home/wallet/index.page"));
const GameSettings = lazy(() => import("./pages/game/settings/index.page"));

const Page404 = lazy(() => import("./pages/404.page"));

function App() {
  return (
    <BrowserRouter basename="/">
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<TopLoading />} key="MainRouter">
          <div className="App">
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="services" element={<Feature />} />
                  <Route path="pricing" element={<Pricing />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="policies" element={<PrivacyPolicy />} />
                  <Route path="login" element={<Login />} />
                  <Route path="forget-password" element={<Forget />} />
                  <Route path="restore-password" element={<ForgetConfim />} />
                  <Route path="register" element={<Register />} />
                  <Route
                    path="console"
                    element={
                      <ProtectedRoute>
                        <Console />
                      </ProtectedRoute>
                    }
                  >
                    <Route path="admin" element={<AdminPage />} />
                    <Route path="wallet" element={<Wallet />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="user/:uid" element={<UserAccount />} />
                    <Route path="game/:id" element={<Game />}>
                      <Route exact path="" element={<GameSummery />} />
                      <Route path="chats" element={<Chat />} />

                      <Route path="storage" element={<Storage />} />

                      <Route path="users">
                        <Route path="" element={<UsersList />} />
                        <Route path=":uid" element={<User />} />
                      </Route>

                      <Route path="economy">
                        <Route path="" element={<EconomyList />} />
                        {/*<Route path=":uid" element={<User/>}/>*/}
                      </Route>

                      <Route path="logic">
                        <Route path="" element={<JourneyList />} />
                        <Route path="new" element={<Journey />} />
                        {/*<Route path=":jid" element={<Journey/>}/>*/}
                      </Route>

                      <Route path="tables">
                        <Route path="" element={<Tables />} />
                        <Route path=":tid" element={<TableView />} />
                        <Route path="new" element={<NewTable />} />
                        <Route path="new_view" element={<NewView />} />
                      </Route>

                      <Route path="achievement">
                        <Route path="" element={<Achievements />} />
                        <Route path=":aid" element={<Achievement />} />
                        <Route path="new" element={<NewAchievement />} />
                      </Route>

                      <Route path="leaderboard">
                        <Route path="" element={<Leaderboards />} />
                        <Route path=":lid" element={<Leaderboard />} />
                        <Route path="new" element={<NewLeaderboard />} />
                      </Route>
                      {/*<Route path="party">*/}
                      {/*  /!*<Route path="/" element={Parties} />*!/*/}
                      {/*  <Route path=":pid" element={<Party/>}/>*/}
                      {/*</Route>*/}
                      <Route path="settings" element={<GameSettings />} />
                      <Route path="computing" element={<Computing />} />
                      <Route path="panel" element={<Panel />} />
                    </Route>
                  </Route>
                  <Route path="/404" element={<Page404 />} />
                  <Route
                    path="*"
                    element={<Navigate replace to="/404" />}
                  />{" "}
                </Routes>
              </PersistGate>
            </Provider>
          </div>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

function ProtectedRoute({ children }) {
  const token = useSelector((state) => state.auth.token);
  if (!token || token === "") return <Navigate to="/login" replace />;

  return children;
}

export default App;
