import { createSlice } from "@reduxjs/toolkit";

const sidebarReducer = createSlice({
  name: "header",
  initialState: {
    haveHeader: true,
  },
  reducers: {
    SetShowHeader(state, action) {
      state.haveHeader = action.payload;
    },
  },
});

export const actions = sidebarReducer.actions;
export default sidebarReducer.reducer;
